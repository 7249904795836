import { useEffect, useState } from 'react'
import { Container, Row, Col, InputGroup, Form, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
// import { Spinner } from 'react-bootstrap'
import { fetchWrapper } from '_helpers/fetch-wrapper'
// import { toast } from 'react-toastify'


export default function Assignments() {

  const { user: authUser } = useSelector(x => x.auth)

  const url = process.env.REACT_APP_URL

  // const presenters = [
  //   { id: 0, name: "Aneta Derkova" },
  //   { id: 1, name: "Jiří Maliňák" },
  //   { id: 2, name: "Josef Hertl" },
  //   { id: 3, name: "Ladislav Štefka" },
  //   { id: 4, name: "Lucie Čermakova" },
  //   { id: 5, name: "Marcela Jakešová" },
  //   { id: 6, name: "Marek Mikulec" },
  //   { id: 7, name: "Marius Cornescu" },
  //   { id: 8, name: "Petr Hrabě" },
  //   { id: 9, name: "Rastislav Cubo" },
  //   { id: 10, name: "Tomáš Valíček" },
  //   { id: 11, name: "Vinod Shetty" },
  //   { id: 12, name: "Zdenek Glatter" },
  //   { id: 13, name: "David Král" },
  //   { id: 14, name: "Michal Michalec" },
  //   { id: 15, name: "Mike Smith" },
  //   { id: 16, name: "Vojtěch Mates" },
  // ]

  // const [isLoading, setIsLoading] = useState(true, [])
  const [roles, setRoles] = useState([])
  const [presenters, setPresenters] = useState([])
  const [selectedRoleId, setSelectedRoleId] = useState(-1)
  const [selectedPresenterId, setSelectedPresenterId] = useState(-1)
  const [assignments, setAssignments] = useState([])

  const getRolesAndPresenters = async () => {
    // setIsLoading(true)
    const approles = await fetchWrapper.get(`${url}/meetingroles`)
    if (!approles.Error) {
      setRoles(approles)
    }
    const apppresenters = await fetch(`${url}/users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authUser.token
      },
    })
    if (!apppresenters.Error) {
      const presenters = await apppresenters.json()
      setPresenters(presenters)
      console.log(presenters)
    }
    else {
      console.log(apppresenters.Error)
    }
    // setIsLoading(false)
  }

  const addAssignment = (e) => {
    e.preventDefault()
    const selectedRole = roles.find(item => item._id.toString() === selectedRoleId)
    const selectedPresenter = presenters.find(item => item._id.toString() === selectedPresenterId)
    setAssignments([...assignments,
    {
      roleId: selectedRole._id,
      roleName: selectedRole.name,
      presenterId: selectedPresenter._id,
      presenterName: selectedPresenter.name
    }
    ])
  }

  const removeAssignment = (e) => {
    e.preventDefault()
    console.log('assignments: ' + assignments.length)
    console.log('Removing assignment ' + e.target.name)

    if (assignments.length === 1) {
      setAssignments([])
    }
    else if (assignments.length > 1) {
      const index = e.target.name;
      const newAssignments = assignments.splice(index, 1)
      setAssignments(newAssignments)
    }
  }

  useEffect(() => {
    if (authUser) {
      getRolesAndPresenters()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      <Row className='mt-5'>
        <Col>
          {
            // isLoading ? <Spinner animation='border' variant='danger' /> :
            authUser &&
            <>
              <Row className='mb-5'>
                <Col><h2>Assignments</h2></Col>
              </Row>
              <Row>
                <Col>
                  <Form data-bs-theme={'dark'}>
                    <InputGroup className='mb-3'>
                      {/* <Form.Group> */}
                      {/* <Form.Label>Role</Form.Label> */}
                      <Form.Select
                        aria-label='Role'
                        className='mb-3 formInput'
                        name='role'
                        onChange={e => setSelectedRoleId(e.target.value)}
                      >
                        <option key={0}>-- Choose a role --</option>
                        {
                          roles.map((role) => (
                            <option key={role._id} value={role._id}>{role.name}</option>
                          ))
                        }
                      </Form.Select>
                      <Form.Select
                        aria-label='Presenter'
                        className='mb-3 formInput'
                        name='presenter'
                        onChange={e => setSelectedPresenterId(e.target.value)}
                      >
                        <option key={0}>-- Choose a presenter --</option>
                        {
                          presenters.map((presenter) => (
                            <option key={presenter._id} value={presenter._id}>{presenter.name}</option>
                          ))
                        }
                      </Form.Select>
                      {/* </Form.Group> */}
                      <Button
                        variant="success"
                        type='submit'
                        className='assignments-button'
                        onClick={addAssignment}
                      >
                        Add
                      </Button>
                    </InputGroup>
                  </Form>
                </Col>
              </Row>
            </>

          }
        </Col>
        <Row>
          <Col>
            <>
              {
                assignments.map((assignment, index) => (
                  <p key={index}>&nbsp;
                    {authUser &&
                      <Button variant="danger" className="assignments-button" name={index} onClick={removeAssignment}>x</Button>
                    }
                    &nbsp;{assignment.roleName} - {assignment.presenterName}</p>
                ))
              }
            </>
          </Col>
        </Row>

      </Row>
    </Container>
  )
}