import { Card, Col, Row, Modal, Button } from 'react-bootstrap'
import { useState } from 'react'

import infoImage from '../../../assets/backgrounds/goldenCardIcebreaker.png'

export const IceBreaker = () => {
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  return (
    <Col xs={12} md={6} xl={4}>
      <Card className="info-cardbx text-center">
        <Card.Img variant="top" src={infoImage} alt="infoImage3" />
        <div className='info-txtx'>
          <h4>Break the Ice</h4>
          <span>Have your first speech</span><br /><br />
          <Button onClick={handleShowModal}>Read more</Button>
        </div>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal} data-bs-theme="dark" centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Break the Ice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h2>Sample your first Project</h2>
              <p>
                Take an exciting peek at the first project on your Toastmasters journey - the Ice Breaker. See how projects are structured in Pathways and experience how clear and simple the objectives are to achieve.
              </p>
              <p>
                The Ice Breaker is your first opportunity to share something about yourself and your goals with other members of your club. You may want to include the following information in your speech to help club members get to know you:
              </p>
              <ul>
                <li>
                  The reason you joined Toastmasters (if a person or incident influenced you to join, share that story)
                </li>
                <li>
                  Your communication and leadership goals
                </li>
              </ul>
              <a href='https://www.toastmasters.org/websiteApps/Pathways/IceBreaker/tm100101/index.html' target='_blank' rel="noreferrer" className="services-link">Learn More</a>
            </Col>
            <Col lg={4} s={12}>
              <iframe title='youtube' width={'100%'} src="https://www.youtube.com/embed/nAgeDAjpvqE?autoplay=0&enablejsapi=1" />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}