import { Card, Col, Button } from 'react-bootstrap'
import infoImage from '../../../assets/backgrounds/goldenCardTimer.png'

export const WebTimer = () => {

  return (
    <Col xs={12} md={6} xl={4}>
      <Card className="info-cardbx text-center">
        <Card.Img variant="top" src={infoImage} alt="Web Timer" />
        <div className='info-txtx'>
          <h4>WEB TM Timer</h4>
          <span>Toastmasters Timer</span><br /><br />
          <Button href="https://tm-timer.ddns.net/" target='_blank' rel="noreferrer" >Open Timer</Button>
        </div>
      </Card>
    </Col>
  )
}