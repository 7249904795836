import { Card, Col, Row, Modal, Button } from 'react-bootstrap'
import { useState } from 'react'
import infoImage from '../../../assets/backgrounds/goldenCardContests.png'

export const Contests = () => {
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  return (
    <Col xs={12} md={6} xl={4}>
      <Card className="info-cardbx text-center">
        <Card.Img variant="top" src={infoImage} alt="Contests" />
        <div className='info-txtx'>
          <h4>Contests</h4>
          <span>Practice what you have learned</span><br /><br />
          <Button onClick={handleShowModal}>Read more</Button>
        </div>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal} data-bs-theme="dark" centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Contests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h2>Contests</h2>
              <p>
                Speech contests are a Toastmasters tradition. Each year, thousands of Toastmasters compete in the Humorous, Evaluation, Tall Tales, Table Topics and International speech contests. Competition begins with club contests, and winners continue competing through the Area, Division and District levels. Winners of the District level International Speech Contest proceed to the region quarterfinal level. Following region quarterfinals, winners advance to the semifinals for a chance to take part in the World Championship of Public Speaking®.
                <br />
                In order to participate to these contests, there is a set of rules.
              </p>
              <p>
                To be eligible to compete in any official Toastmasters speech contest, a member must:
              </p>
              <ul>
                <li>
                  be a paid member of a club in the Area, Division, and District in which they are competing.
                </li>
                <li>
                  have earned certificates of completion in Levels 1 and 2 in any path in the Toastmasters Pathways learning experience or earned a Distinguished Toastmaster award.
                </li>
                <li>
                  not be part of District officers whose terms expire June 30 or candidates for elected positions for the term beginning in the upcoming July 1.
                </li>
              </ul>
              <p>
                To be a chief judge, voting judge or tiebreaking judge at a Toastmasters speech contest you must meet all eligibility requirements identified below.
              </p>
              <ol>
                <li>
                  At a club contest, be a paid member.
                </li>
                <li>
                  At an Area, Division or District Contest:
                  <ul>
                    <li>
                      be a paid member for a minimum of six (6) months
                    </li>
                    <li>
                      have completed a minimum of six (6) speech projects or earned certificates of completion in Levels 1 and 2 of any path in the Toastmasters Pathways learning experience.
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                All other contest officials and the Evaluation Contest test speaker must be paid members.
              </p>
              <a href='https://www.toastmasters.org/Resources/Speech%20Contests' target='_blank' rel="noreferrer" className="services-link">Learn More</a>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}