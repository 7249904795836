import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import { useEffect, useState } from 'react'

// import './FormModal.css'

export default function MeetingEditModal({ meeting, getMeetings = null }) {
    const url = process.env.REACT_APP_URL

    const { user: authUser } = useSelector(x => x.auth)

    const nextTuesday = new Date()
    nextTuesday.setDate(nextTuesday.getDate() + (((2 + 7 - nextTuesday.getDay()) % 7) || 7))

    const [showEditMeetingModal, setShowEditMeetingModal] = useState(false)
    const [meetingType, setMeetingType] = useState('Regular')
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [meetingDate, setMeetingDate] = useState('')
    const [meetingTime, setMeetingTime] = useState('18:30')
    const [meetingAddress, setMeetingAddress] = useState('KŘENOVÁ 57, OBČANSKÝ KLUB')
    const [meetingNumber, setMeetingNumber] = useState(undefined)
    const [meetingTheme, setMeetingTheme] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [facebookUrl, setFacebookUrl] = useState('')
    const [youtubeUrl, setYoutubeUrl] = useState('')
    const [meetingDescription, setMeetingDescription] = useState('')


    const [saveLoading, setSaveLoading] = useState(false)
    const handleCloseEditMeetingModal = () => setShowEditMeetingModal(false)
    const handleShowEditMeetingModal = () => setShowEditMeetingModal(true)

    useEffect(function () {
        setMeetingType(meeting.meetingType)
        setMeetingDate(meeting.meetingDate)
        setSelectedDate(moment(meeting.meetingDate, "DD/MM/YYYY").toDate())
        setMeetingTime(meeting.meetingTime)
        setMeetingAddress(meeting.meetingAddress)
        if (!meeting.meetingNumber) {
            setMeetingNumber('')
        }
        else { setMeetingNumber(meeting.meetingNumber) }
        setMeetingTheme(meeting.meetingTheme)
        if (!meeting.imageUrl) {
            setImageUrl('')
        }
        else { setImageUrl(meeting.imageUrl) }

        if (!meeting.facebookUrl) {
            setFacebookUrl('')
        }
        else { setFacebookUrl(meeting.facebookUrl) }
        if (!meeting.youtubeUrl) {
            setYoutubeUrl('')
        }
        else { setYoutubeUrl(meeting.youtubeUrl) }

        if (!meeting.meetingDescription) {
            setMeetingDescription('')
        }
        else { setMeetingDescription(meeting.meetingDescription) }
    }, [meeting.meetingType, meeting.meetingDate, meeting.meetingTime, meeting.meetingAddress, meeting.meetingNumber, meeting.meetingTheme, meeting.imageUrl, meeting.facebookUrl, meeting.youtubeUrl, meeting.meetingDescription])

    const handleSubmit = (e) => {
        e.preventDefault()
        setSaveLoading(true)
        const validate = true;
        if (validate === true) {
            fetch(url + '/meeting/' + meeting._id, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authUser.token
                },
                body: JSON.stringify({
                    meetingType: meetingType,
                    meetingDate: meetingDate,
                    meetingTime: meetingTime,
                    meetingAddress: meetingAddress,
                    meetingNumber: meetingNumber,
                    meetingTheme: meetingTheme,
                    imageUrl: imageUrl,
                    facebookUrl: facebookUrl,
                    youtubeUrl: youtubeUrl,
                    meetingDescription: meetingDescription
                })
            })
                .then(res => {
                    if (res.status !== 200 && res.status !== 201) {
                        throw new Error('(' + res.status + ') We could not save your new meeting')
                    }
                    return res.json()
                })
                .then(resData => {
                    // console.log("response JSON: " + resData)
                    toast.success('Your meeting ' + meetingTheme + ' has been updated', { position: 'bottom-left' })
                    setSaveLoading(false)
                    if (getMeetings) getMeetings()
                    handleCloseEditMeetingModal()
                })
                .catch(err => {
                    toast.error('' + err, { position: 'bottom-left' })
                    setSaveLoading(false)
                })
        }
    }

    return (
        <>
            <Dropdown.Item onClick={handleShowEditMeetingModal}>Edit Meeting</Dropdown.Item>
            <Modal centered show={showEditMeetingModal} onHide={handleCloseEditMeetingModal} className='text-center' data-bs-theme={'dark'}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Meeting:<br /> {meeting.meetingTheme}
                    </Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <Form.Select
                            aria-label='Meeting Type'
                            className='mb-3'
                            name='meetingType'
                            value={meetingType}
                            onChange={e => setMeetingType(e.target.value)}
                        >
                            {/* <option value=''>Choose a meeting type:</option> */}
                            <option value='Regular'>Regular</option>
                            <option value='Committee'>Committee</option>
                            <option value='Contest'>Contest</option>
                            <option value='Other'>Other</option>
                        </Form.Select>
                        <InputGroup className='mb-3'>
                            <DatePicker
                                wrapperClassName='formInput'
                                showIcon
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}

                            /> &nbsp;&nbsp;&nbsp;&nbsp;
                            <Form.Control
                                className='formInput'
                                placeholder='Time: HH:mm'
                                aria-label='Time'
                                name='meetingTime'
                                value={meetingTime}
                                onChange={e => setMeetingTime(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Location'
                                aria-label='Location'
                                name='meetingAddress'
                                value={meetingAddress}
                                onChange={e => setMeetingAddress(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Meeting Number'
                                aria-label='meetingNo'
                                name='meetingNumber'
                                value={meetingNumber}
                                onChange={e => setMeetingNumber(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Meeting Theme'
                                aria-label='meeting Theme'
                                name='meetingTheme'
                                value={meetingTheme}
                                onChange={e => setMeetingTheme(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control as="textarea"
                                className='formInput'
                                placeholder='Meeting Description'
                                aria-label='meeting Description'
                                rows={5}
                                name='meetingDescription'
                                value={meetingDescription}
                                onChange={e => setMeetingDescription(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='meeting Picture'
                                aria-label='meeting Picture'
                                name='imageUrl'
                                value={imageUrl}
                                onChange={e => setImageUrl(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='meeting facebook event url'
                                aria-label='meeting facebook event url'
                                name='facebookUrl'
                                value={facebookUrl}
                                onChange={e => setFacebookUrl(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Meeting Youtube Recording'
                                aria-label='meeting Youtube Recording'
                                name='youtubeUrl'
                                value={youtubeUrl}
                                onChange={e => setYoutubeUrl(e.target.value)}
                            />
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        {saveLoading
                            ?
                            'Saving meeting ... '
                            :
                            <>
                                <Button variant="success" type='submit' onClick={handleSubmit}>
                                    Save Meeting
                                </Button>
                                <Button variant="danger" onClick={handleCloseEditMeetingModal}>
                                    Cancel
                                </Button>
                            </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}


