import { Card, Col, Row, Modal, Button } from 'react-bootstrap'
import { useState } from 'react'
import infoImage from '../../../assets/backgrounds/goldenCardAboutToastmasters.png'

export const AboutTM = () => {
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  return (
    <Col xs={12} md={6} xl={4}>
      <Card className="info-cardbx text-center">
        <Card.Img variant="top" src={infoImage} alt="About Toastmasters" />
        <div className='info-txtx'>
          <h4>About Toastmasters</h4>
          <span>Toastmasters International Organization</span><br /><br />
          <Button onClick={handleShowModal}>Read More</Button>
        </div>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal} data-bs-theme="dark" centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>About Toastmasters International Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h2>About Toasmasters International Organization</h2>
              <p>
                Toastmasters International is a nonprofit educational organization that builds confidence and teaches public speaking skills through a worldwide network of clubs that meet online and in person. In a supportive community or corporate environment, members prepare and deliver speeches, respond to impromptu questions, and give and receive constructive feedback. It is through this regular practice that members are empowered to meet personal and professional communication goals. Founded in 1924, the organization is headquartered in Englewood, Colorado with approximately 270,000 members in more than 14,200 clubs in 148 countries.
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}