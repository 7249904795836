import { Card, Col, Row, Modal, Button } from 'react-bootstrap'
import { useState } from 'react'
import infoImage from '../../../assets/backgrounds/goldenCardPathways.png'

export const Education = () => {
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  return (
    <Col xs={12} md={6} xl={4}>
      <Card className="info-cardbx text-center" variant="dark">
        <Card.Img variant="top" src={infoImage} alt="infoImage2" />
        <div className='info-txtx'>
          <h4>Education and Pathways</h4>
          <span>Choose your own path</span><br /><br />
          <Button onClick={handleShowModal}>Read more</Button>
        </div>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal} data-bs-theme="dark" centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Education & Pathways</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={4} s={12}>
              <iframe title='youtube' width={'100%'} src="https://www.youtube.com/embed/iv9cKPn0Wpo?autoplay=0&enablejsapi=1" />
            </Col>
            <Col>
              <h2>Education</h2>
              <p>Education is an important part of every Toastmaster's journey, and it is a critical aspect of all club meetings around the world. Participating in Toastmasters' education programs allows you to gain the valuable practice needed to develop your public speaking, communication, and leadership skills. The safe and supportive environment clubs provide allows you to learn at your own pace in a curriculum that has helped millions meet their personal and professional goals.</p>
              <a href='https://www.toastmasters.org/education' target='_blank' rel="noreferrer" className="services-link">Learn More</a>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>What are Pathways</h2>
              <p>Toastmasters Pathways learning experience, an exciting, flexible and interactive way to develop your skills and help others in your club develop theirs. Pathways helps you learn communication and leadership skills that you need to succeed.</p>
              <a href='https://www.toastmasters.org/pathways-overview' target='_blank' rel="noreferrer" className="services-link">Learn More</a>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}